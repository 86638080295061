import callcenter2Connection from '@/api/callcenter2.js';

const state = () => ({
  templates: {},
  categories: {},
  pagination: [],
  lastUpdate: [],
  applicationId: null,
  itemPerPage: 100,
  lastDepartment: null,
  isLoading: false
});

const getters = {
  getTemplatesByDepartment: (state) => (departmentId) => state.templates[departmentId] || [],
  getTemplates: (state) => state.templates,
  getLastPageByDepartment: (state) => (departmentId) => state.pagination[departmentId]?.lastPage || 1,
  getCurrentPageByDepartment: (state) => (departmentId) => state.pagination[departmentId]?.currentPage || 1,
  getIsLoading: (state) => state.isLoading,
  getApplicationId: (state) => state.applicationId
};

const mutations = {
  SET_TEMPLATES(state, { departmentId, templates }) {
    state.templates = { ...state.templates, [departmentId]: templates };
    state.lastUpdate[departmentId] = Date.now();
  },
  SET_LAST_PAGE(state, { departmentId, lastPage }) {
    if (!state.pagination[departmentId]) {
      state.pagination[departmentId] = { currentPage: 1 };
    }
    state.pagination[departmentId].lastPage = lastPage;
  },
  INCREMENT_CURRENT_PAGE(state, departmentId) {
    if (!state.pagination[departmentId]) {
      state.pagination[departmentId] = { currentPage: 1 };
    }
    state.pagination[departmentId].currentPage++;
  },
  RESET_PAGINATION(state, departmentId) {
    if (state.pagination[departmentId]) {
      state.pagination[departmentId].currentPage = 1;
    }
  },
  SET_LAST_DEPARTMENT(state, department){
    state.lastDepartment = department
  },
  SET_IS_LOADING(state, value){
    state.isLoading = value
  },
  ADD_TEMPLATES(state, { departmentId, templates }){
    state.templates[departmentId] = [...state.templates[departmentId], ...templates ];
  },
  SET_APPLICATION_ID(state, value){
    state.applicationId = value
  }
};

const actions = {
  checkLastUpdate({ state, commit, dispatch }, {department}){
    if(department){
      const currentTime = Date.now();
      const oneHourInMilliseconds = 60*60*1000;
      if(state.lastUpdate[department.id] && (currentTime - state.lastUpdate[department.id]) > oneHourInMilliseconds){
        commit('RESET_PAGINATION', department.id)
        dispatch('fetchTemplates', {department})
      }
    }
  },
  async fetchTemplates({ commit, state, rootGetters }, { department, add }) {
    
    try {
      const config = rootGetters.getConfiguration;
      commit('SET_IS_LOADING', true)
      const currentPage = state.pagination[department.id]?.currentPage || 1;
      

      // Paso 1: Obtener el ID de la aplicación utilizando el número del departamento
      const applications = await callcenter2Connection.getApplicationId(department.number);
      const application = applications.data['hydra:member'].find(element => element.phone === department.number);

      if (application) {
        const applicationId = application.id;
        commit('SET_APPLICATION_ID', applicationId)
        // Paso 2: Obtener los templates usando el ID de la aplicación y la página actual
        const response = await callcenter2Connection.getTemplatesWsp(applicationId, currentPage, state.itemPerPage);
        let data = response.data['hydra:member'];

        // Paso 3: Extraer la última página desde la respuesta y guardarla en el estado
        
        if(state.pagination[department.id] && !state.pagination[department.id].lastPage){
          const lastPageMatch = response.data['hydra:view']?.['hydra:last']?.match(/page=(\d+)/);
          const lastPage = lastPageMatch ? parseInt(lastPageMatch[1], 10) : 1;
          commit('SET_LAST_PAGE', { departmentId: department.id, lastPage });
        }
        

        // Filtrar templates aprobados
        data = data.filter(element => element.status === 'APPROVED');
        commit('INCREMENT_CURRENT_PAGE', department.id);

        // Continuar obteniendo más datos hasta tener al menos 9 elementos o llegar a la última página
        if(!add){
          while (data.length < 9 && state.pagination[department.id].currentPage <= state.pagination[department.id].lastPage) {
            const nextResponse = await callcenter2Connection.getTemplatesWsp(applicationId, state.pagination[department.id].currentPage, state.itemPerPage);
            const nextData = nextResponse.data['hydra:member'].filter(element => element.status === 'APPROVED');
            data = [...data, ...nextData];
            commit('INCREMENT_CURRENT_PAGE', department.id);
          }
        }
        

        // Paso 4: Formatear los templates antes de guardarlos en el estado
        const formattedTemplates = data.map(element => ({
          id: element.id,
          name: element.elementName,
          data: element.data,
          text: element.containerMetaDecode?.data,
          templateType: element.templateType,
          header: element.containerMetaDecode?.header || '',
          footer: element.containerMetaDecode?.footer || '',
          buttons: element.containerMetaDecode?.buttons || [],
          category: element.category,
          img: element.templateType === 'IMAGE'
            ? `https://${config.storageBucket}.storage.googleapis.com/whatsapp/template/${element.elementName}/${element.elementName}.jpg`
            : ''
        }));

        // Guardar los templates en el estado
        if(!add){
          commit('SET_TEMPLATES', { departmentId: department.id, templates: formattedTemplates });
          return
        }
        commit('ADD_TEMPLATES', { departmentId: department.id, templates: formattedTemplates })
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }finally{
      commit('SET_IS_LOADING', false)
    }
  },
  increaseCurrentPage({commit}, {department}){
    commit('INCREMENT_CURRENT_PAGE', department.id)
  },
  resetCurrentPage({commit, state}, {department}){
    commit('RESET_PAGINATION', department.id)
    console.log(state.pagination[department.id].currentPage)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
