import phoneWidgetApi from '@/api/phoneWidget'
import createStore from '../helper'

const store = createStore('phone_widgets')

const actions = {
  togglePhoneWidgetActive({ commit }, { active, id}) {
    return new Promise((resolve, reject) => {
      phoneWidgetApi.togglePhoneWidgetActive(id, active)
      .then((response) => {
        console.log(response)
        const phoneWidget = response.data
        commit('UPDATE_DATA', phoneWidget)
        return resolve(phoneWidget)
      })
      .catch((error) => {
        reject(error)
      });
    })
  }
}

store.actions = {...store.actions, ...actions}

export default store