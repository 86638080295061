<template>
  <div>
    <div v-if="isExtensionRegistered" class="phone-widget" :style="{ fontFamily: phoneWidgetParams.styles.fontFamily}">
      <PhoneButton
        :phoneWidgetButtonsParams="phoneWidgetParams"
        @click="openWidget"
        v-if="!isShowDropdown"
        :isInCall="isInCall"
        :isCalling="isCalling"
      ></PhoneButton>
      <div v-if="isShowDropdown" class="phone-widget__dropdown-container" :style="{background: phoneWidgetParams.styles.widgetBackground}">
        <div class="phone-widget__dropdown-minimize">
          <svg
            @click="closeWidget"
            class="phone-widget__dropdown-minimize-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32l448 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 416z"
            />
          </svg>
        </div>
        <div class="phone-widget__dropdown-body">
          <div class="phone-widget__dropdown-advices-container">
            <div
              class="phone-widget__dropdown-advice-body phone-widget__dropdown-advice-idle"
              v-if="!isCalling && !isInCall"
            >
              <p>{{ phoneWidgetParams.data.idleState.mainMessage }}</p>
              <div
                class="phone-widget__dropdown-advice-idle-circle"
                :style="{width: btnSize, height:btnSize}"
                @click="handleCall"
              >
                <svg
                  class="phone-widget__dropdown-advice-idle-circle-icon"
                  :style="{width: btnIconSize, height: btnIconSize}"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                  />
                </svg>
              </div>
            </div>
            <div
              class="phone-widget__dropdown-advice-body phone-widget__dropdown-advice-ringing"
              v-if="isCalling"
            >
              <div class="phone-widget__dropdown-top-advices-container">
                <div class="phone-widget__dropdown-advice-calling">
                  <p>{{ phoneWidgetParams.data.callingState.mainMessage }}</p>
                  <div class="phone-widget__dropdown-advice-calling-circle" :style="{width: btnSize, height:btnSize}">
                    <svg
                      class="phone-widget__dropdown-advice-calling-circle-icon"
                      :style="{width: btnIconSize, height: btnIconSize}"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#ffffff"
                        d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="phone-widget__dropdown-headphones-advice">
                <p>{{ phoneWidgetParams.data.callingState.secondaryMessage }}</p>

                <svg
                  class="phone-widget__dropdown-headphones-advice-image"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M256 80C149.9 80 62.4 159.4 49.6 262c9.4-3.8 19.6-6 30.4-6c26.5 0 48 21.5 48 48v128c0 26.5-21.5 48-48 48c-44.2 0-80-35.8-80-80v-112C0 146.6 114.6 32 256 32s256 114.6 256 256v112c0 44.2-35.8 80-80 80c-26.5 0-48-21.5-48-48V304c0-26.5 21.5-48 48-48c10.8 0 21 2.1 30.4 6C449.6 159.4 362.1 80 256 80z"
                  />
                </svg>
              </div>
            </div>

            <div
              class="phone-widget__dropdown-advice-body phone-widget__dropdown-advice-in-call"
              v-if="isInCall"
            >
              <p>{{ phoneWidgetParams.data.inCallState.mainMessage }}</p>
              <span>{{ formatedTimer }}</span>
              <div class="phone-widget__dropdown-advice-in-call-circle" :style="{width: btnSize, height:btnSize}">
                <svg
                  @click="hangup"
                  class="phone-widget__dropdown-advice-in-call-circle-icon"
                  :style="{width: btnIconSize, height: btnIconSize}"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneButton from "./PhoneButton.vue";

export default {
  components: {
    PhoneButton,
  },
  props: {
    phoneWidgetParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowDropdown: false,
      isInCall: false,
      isCalling: false,
      timer: 0,
      timerInterval: null,
      isExtensionRegistered: true
    };
  },
  computed: {
    formatedTimer() {
      const minutes = Math.floor(this.timer / 60);
      const seconds = this.timer % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    btnSize(){
      console.log(this.phoneWidgetParams.styles.actionBtnSize)
      return this.phoneWidgetParams.styles.actionBtnSize
    },
    btnIconSize(){
      const size = this.phoneWidgetParams.styles.actionBtnSize
      return (Number.parseInt(size.replace('px', '')) - 25) + 'px'
    }
  },
  methods: {
    handleCall() {
      if (!this.isInCall && !this.isCalling) {
        this.isCalling = true;
      }
    },
    hangup() {
      this.isInCall = false;
      this.isCalling = false;
    },
    onCallStart() {
      this.isInCall = true;
      this.isCalling = false;
    },
    onCallEnd() {
      this.isInCall = false;
    },
    openWidget(){
      this.$emit("openWidget")
      this.isShowDropdown = true;
    },
    closeWidget(){
      this.$emit("closeWidget");
      this.isShowDropdown = false;
    }
  }
};
</script>
<style scoped>
.phone-widget__dropdown-container {
  width: 30vw;
  max-width: 280px;
  height: 400px;
  position: relative;
  border-radius: 10px;
}
.phone-widget__dropdown-minimize {
  position: absolute;
  top: 0;
  right: 15px;
}
.phone-widget__dropdown-minimize-icon {
  width: 20px;
  cursor: pointer;
}
.phone-widget__dropdown-header {
  height: 60px;
  display: flex;
  border-radius: 9px 9px 0 0;
  align-items: center;
  padding: 0 10px 0 10px;
}
.phone-widget__dropdown-header .phone-widget__dropdown-header-image {
  width: 50px;
  height: 50px;
  border-radius: 400px;
}
.phone-widget__dropdown-body {
  width: 100%;
  height: 100%;
  max-height: 400px;
  border-radius: 10px 10px 10px 10px;
  background: var(--widget-background);
}
.phone-widget__dropdown-advices-container {
  font-size: 17px;
  height: 100%;
  text-align: center;
}
.phone-widget__dropdown-advice {
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
  font-size: 17px;
}
.phone-widget__dropdown-advice-body {
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
  font-size: 17px;
  overflow: hidden;
}
.phone-widget__dropdown-headphones-advice {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  padding: 25px 10px 0 10px;
  gap: 15px;
  height: 200px;
}
.phone-widget__dropdown-headphones-advice-image {
  width: 40px;
}

.phone-widget__dropdown-top-advices-container {
  height: 200px;
  display: flex;
  align-items: flex-end;
  padding: 0 10px 25px 10px;
}

/* IN IDLE*/
.phone-widget__dropdown-advice-idle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
  padding: 0 10px;
}
.phone-widget__dropdown-advice-idle-circle {
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: 50%;
  background: rgba(167, 199, 29, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.phone-widget__dropdown-advice-idle-circle-icon {
  width: calc(var(--btn-size) - 25px);
  height: calc(var(--btn-size) - 25px);
  color: white;
}

/* IN CALL */
.phone-widget__dropdown-advice-in-call-circle {
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: 50%;
  background: rgb(243, 33, 33);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.phone-widget__dropdown-advice-in-call-circle-icon {
  width: calc(var(--btn-size) - 25px);
  height: calc(var(--btn-size) - 25px);
  transform: rotate(138deg);
}
.phone-widget__dropdown-advice-in-call {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
}

/* IN CALLING */
.phone-widget__dropdown-advice-calling {
  display: flex;
  font-size: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 25px;
  padding: 0 10px 0 10px;
}

.phone-widget__dropdown-advice-calling-circle-icon {
  width: calc(var(--btn-size) - 25px);
  height: calc(var(--btn-size) - 25px);
  animation: phone-shake 2000ms ease 0ms infinite normal forwards;
}
/*Loaders*/
.phone-widget__dropdown-advice-calling-circle {
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: 50%;
  background: rgba(212, 225, 87, 1);
  box-shadow: 0 0 0 0 rgba(212, 225, 87, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse-c6zlskmd 1s infinite;
}

@keyframes pulse-c6zlskmd {
  100% {
    box-shadow: 0 0 0 33.6px #0000;
  }
}

@keyframes phone-shake {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(8deg);
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(-8deg);
  }

  90% {
    transform: rotate(8deg);
  }
}
</style>
