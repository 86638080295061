<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :canDelete="false" :search='search' :goBack="goBack" :init-query-params="{
        ['extension.organization.id'] : organizationId
      }">
      <template v-slot:action-buttons="scope">
        <el-dropdown-item icon="fas fa-copy" @click.native="generatePhoneWidget(scope.row)">{{ $t('phone-widget.generate-widget') }}</el-dropdown-item>
        <el-dropdown-item :icon="scope.row.isActive == 1 ? 'fas fa-trash' : 'fas fa-trash-restore'" @click.native="handleActive(scope.row, scope.row.isActive === 1 ? 0 : 1)">
          {{ scope.row.isActive == 1 ? $t('phone-widget.desactivate') : $t('phone-widget.activate') }}
        </el-dropdown-item>
      </template>  
    </the-crud>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'

export default {
  data() {
    return {
      search: ['name'],
      title: this.$t("phone-widget.menu-title"),
      store: "phoneWidgets",
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t("common.name")},
        // { index : 'type', label : this.$t('contact-me.chat-center.quick-response-template.type') },
        { index : 'callTo', label : this.$t('phone-widget.destination')},
        { index : 'extension.extension', label : this.$t('contact-me.pbx.extensions.extension')},
        { index: 'isActive', label: this.$t('common.isActive'), type : 'boolean'}
      ]
    }
  },
  components: {
    TheCrud
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    },
    async generatePhoneWidget(phoneWidget){
      const loading = this.$loading({
          lock: true,
          text: 'Generando widget',
          spinner: 'el-icon-loading',
      });
      let response = await fetch('https://cdn.jsdelivr.net/gh/danik363/hiperme-softphone-widget-budle@latest/index.html?cache-clear')
      let htmlContent = await response.text()
    
      htmlContent = htmlContent.replace('__CALLBACK__', phoneWidget.callback)

      const stylesTag = `<link id="dynamicStylesheet" rel="stylesheet" type="text/css" href="https://storage.googleapis.com/${this.getConfig.storageBucket}/phone-widgets/${phoneWidget.extension.organization.id}/${phoneWidget.callback}.css" crossorigin="anonymous"/>`;

      htmlContent = htmlContent.replace('__STYLES__', stylesTag)
      const otroTag = '<script src="' + process.env.VUE_APP_WIDGET_CDN + '/main.js?no-cache=' + new Date().toISOString() + '"></' + 'script>';
      console.log(otroTag)
      htmlContent = htmlContent.replace('__SCRIPT__', otroTag)
      const downloadLink = document.createElement('a')
      const blob = new Blob([htmlContent], {type: 'text/plain'})
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'index.html'
      downloadLink.click()
      URL.revokeObjectURL(downloadLink.href)
      loading.close();
    },
    async handleActive(phoneWidget, active){
      const loading = this.$loading({
          lock: true,
          text: 'Aplicando cambios',
          spinner: 'el-icon-loading',
      });
      try {
        
        await this.$store.dispatch('phoneWidgets/togglePhoneWidgetActive', {id: phoneWidget.id, active})
      } catch (error) {
        console.log(error)
      }finally{
        loading.close()
      }

      
    }
  },
  computed: {
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    },
    getConfig(){
      return this.$store.getters['getConfiguration']
    }
  }
}
</script>