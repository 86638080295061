var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-button",style:({
    backgroundColor: _vm.getBackgroundColor,
    width: _vm.getButtonSize,
    height: _vm.getButtonSize,
  }),on:{"click":_vm.open}},[(_vm.phoneWidgetButtonsParams.data.logoImage)?_c('img',{staticClass:"phone-button__widget-icon",style:({
      height: _vm.phoneWidgetButtonsParams.styles.logoDimension,
      width: _vm.phoneWidgetButtonsParams.styles.logoDimension,
    }),attrs:{"crossorigin":"anonymous","src":_vm.phoneWidgetButtonsParams.data.logoImage,"alt":"widget-button-icon"}}):_vm._e(),_c('canvas',{ref:"canvas",staticStyle:{"display":"none"}}),_c('p',{style:({ 'font-size': _vm.phoneWidgetButtonsParams.styles.iconFontSize })},[_vm._v(" "+_vm._s(_vm.phoneWidgetButtonsParams.data.iconMessage)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }