<template>
  <div>
    <el-steps :space="400" :align-center="true" :active="active" finish-status="success" v-if="active < 5" @keydown.enter="handleEnterKey">
      <el-step class="step" :title="$t('chatTemplate.step0Title')"></el-step>
      <el-step class="step" :title="$t('chatTemplate.step1Title')"></el-step>
      <el-step class="step" :title="$t('chatTemplate.step2Title')"></el-step>
      <el-step class="step" :title="$t('chatTemplate.step3Title')"></el-step>
      <el-step class="step" :title="$t('chatTemplate.step4Title')"></el-step>
    </el-steps>
    <div class="contentContainer">
      <div class="stepsView">
        <div class="step-view step-view-0" v-loading = "isLoading" v-if="active == 0">
            
            <el-select v-model="departmentSelectedId" :placeholder="$t('chatTemplate.step1Title')">
              <el-option v-for="(department, key) in departmentsWithNumber" :key="key" :label="department.name" :value="department.id"></el-option>
            </el-select>

        </div>
        <div :class="['step-view', 'step-view-1', {'is-loading': isLoading}]" v-loading = "isLoading||isLoadingTemplates" v-if="active == 1">
          <div class="step-view-1__templates-filter-container" ref="scrollContainer" @scroll="handleScroll">
            <el-form class="step-view-1__form__filter">
              <el-form-item class="step-view-1__form__filter-item">
                <div class="step-view-1__form__filter-item-container">
                  <el-input placeholder="Ingrese nombre de plantilla" class="step-view-1__form__filter-item-input" v-model="templateFilter"></el-input>
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu class="step-view-1__form__filter-item-dropdown-menu" slot="dropdown">
                      <el-checkbox-group type="step-view-1__form__filter-item-select" v-model="categoriesFiltered">
                        <el-checkbox v-for="(category , key) in  categories" :key="key" :label="category"></el-checkbox>
                      </el-checkbox-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <el-button class="step-view-1__form__filter-item-refresh-templates-button" icon="fa fa-sync-alt" type="info" @click="refreshTemplates">{{ $t('chatTemplate.refresh-templates') }}</el-button>

              </el-form-item>
              
            </el-form>
            <div class="step-view-1__templates-container">
              <div :class="['card', selectedTemplateId == template.id ? 'selected' : '']" v-for="template in filteredTemplate" :key="template.id"  @click="selectTemplate(template.id)">
                <span class="template-card-title">{{template.name}}</span>
                <span class="template-card-subtitle">{{template.category}}</span>
              </div>
            </div>
          </div>
          <TheChatPreview :selectedTemplateContent="selectedTemplateContent" v-if="selectedTemplateContent"></TheChatPreview>
        </div>
        <div class="step-view step-view-2" v-if="active == 2">
          <el-form class="form">
              <el-form-item v-for="(n, key) in templateVariables" :key="key" :label="$t('chatTemplate.field', { number: key + 1 })">
              <el-input v-model="templateVariables[key]"></el-input>
            </el-form-item>
          </el-form>   
          <TheChatPreview :selectedTemplateContent="selectedTemplateContent" :templateVariables="templateVariables"></TheChatPreview>       
        </div>
        <div class="step-view step-view-3" v-if="active == 3">
          <el-form>
            <el-form-item class="formItem-step-view-3" :label="$t('chatTemplate.enterDestiny')">
              <div class="formItem-container-step-view-3">
                <el-select v-model="selectedCountry" filterable :class="['formItem-container-step-view-3-select-country']" :filter-method="filterMethod">
                  <template #prefix>
                      <img v-if="selectedCountryObj" :src="selectedCountryObj.flag" width="30" style="margin-right: 20px;">
                  </template>
                  <el-option :label="country.label" :value="country.countryCode" v-for="(country) in filteredCountries" :key="country.code">
                    <span class="formItem-container-step-view-3-option-country">
                      <img width="30" height="20" :src="country.flag" v-if="country.code" lazy>
                      <span>{{ country.name }}</span>
                      <span style="color: #8492a6; font-size: 13px">{{ country.label }}</span>
                    </span>
                  </el-option>
                </el-select>
                <el-input v-if="selectedCountry && selectedCountryObj.prefix" disabled :value="selectedCountryObj.prefix" style="width:80px; text-align:center"></el-input>
                <el-input v-model="clientPhone" :placeholder="$t('chatTemplate.phoneNumberDestiny')"></el-input>
              </div>
              <!-- <div class="formItem-step-view-3-help-container">
                <span class="formItem-step-view-3-help">{{ $t('chatTemplate.help-destination') }}</span>
                <span class="formItem-step-view-3-more-help">{{ $t('chatTemplate.more-destination-help') }} <a target="_blank" :href=sendTemplateDoc>{{ $t('common.here') }}</a></span> 
              </div> -->
              </el-form-item>
          </el-form>
        </div>
        <div class="step-view step-view-4" v-if="active == 4">
          <div class="form">
            <div class="el-form-item">
              <span>
                <strong>{{ $t('chatTemplate.departmentSelected') }}:</strong> {{ departmentsWithNumber.filter((element) => element.id == departmentSelectedId)[0].name }}
              </span>
            </div>
            <div class="el-form-item">
              <span>
                <strong>{{ $t('chatTemplate.phoneNumberDestiny') }}:</strong> {{selectedCountryObj.countryCode}} {{selectedCountryObj.prefix ? selectedCountryObj.prefix : ''}} {{ clientPhone }}
              </span>
            </div>
          </div>   
          <TheChatPreview :selectedTemplateContent="selectedTemplateContent" :templateVariables="templateVariables"></TheChatPreview>       
        </div>
        <div class="step-view step-view-5" v-if="active == 5">
          <div v-loading="isLoading">
            <img src="@/img/undraw_begin_chat_re_v0lw.svg" width="200"/>
          </div>
          <p class="text-center">{{ $t('chatTemplate.sendingTemplatePleaseWait', {phone: phone}) }}</p>
        </div>
      </div>
      <div :class="[active > 0 ? 'contentContainer_btns' : 'contentContainer_onlyOneBtn']">
        <el-button style="margin-top: 12px;" @click="backStep" v-if="(active > 0 && active < 5) && !isLoading">{{$t('common.backStep')}}</el-button>
        <el-button style="margin-top: 12px;" @click="nextStep" v-if="active < 5 && !isLoading" type="primary">{{ active === 4 ? $t('agent-console.send') : $t('common.nextStep') }}</el-button>
      </div>
    </div>    
  </div>
</template>
<script>
import callcenter2Connection from '@/api/callcenter2.js'
import { mapGetters, mapActions } from 'vuex'
import TheChatPreview from './TheChatPreview.vue'
import countries from '@/countries.json'

export default {
  data(){
    return {
      active: 0,
      departmentSelectedId: '',
      selectedTemplateId: 1,
      selectedTemplateContent: null,
      clientPhone: '',
      templateVariables: [],
      departmentsWithNumber: [],
      isLoading: false,
      sendTemplateDoc: '',
      handleKeydown: null,
      templateFilter: '',
      categories: [],
      categoriesFiltered: [],
      selectedCountry: null,
      querySelectedCountry: '',
      intervals: []
    }
  },
  methods: {
    ...mapActions({fetchTemplates:'whatsappTemplates/fetchTemplates', 
      increaseCurrentPage:'whatsappTemplates/increaseCurrentPage',
      resetCurrentPage:'whatsappTemplates/resetCurrentPage',
      checkLastUpdate:"whatsappTemplates/checkLastUpdate"}),
    async nextStep(){
      if(this.active++ > 5) this.active = 0;
      if(this.active == 1){
        if(this.$store.getters['whatsappTemplates/getTemplatesByDepartment'](this.departmentSelectedId).length === 0){
          await this.fetchTemplatesLocal()
        }else{
          this.selectedTemplateId = this.templates[this.departmentSelectedId].length > 0 ? this.templates[this.departmentSelectedId][0].id : 1;
          this.selectedTemplateContent = this.templates[this.departmentSelectedId].filter((element) => element.id == this.selectedTemplateId)[0];
          this.checkLastUpdate({department: this.selectedDepartment})
        }
      }
      if(this.active == 2){
        this.selectedTemplateContent = this.templates[this.departmentSelectedId].filter((element) => element.id == this.selectedTemplateId)[0];
        // this.selectedTemplateContent.img = this.selectedTemplateContent.templateType == 'IMAGE' ? `https://${this.config.storageBucket}.storage.googleapis.com/whatsapp/template/${this.selectedTemplateContent.name}/${this.selectedTemplateContent.name}.jpg` : ''
        const variableCant = Array.from(this.selectedTemplateContent.data.matchAll(/{{\d+}}/g));
        this.templateVariables = [];
        for(let i = 0; i < variableCant.length; i++){
          this.templateVariables[i] = '';
        }
      }
      if(this.active == 3){
        const emptyVariables = this.templateVariables.filter((element) => element.trim().length == 0);
        if(this.templateVariables.length > 0 && emptyVariables.length > 0){
          this.active--;
          this.$alert(this.$t('chatTemplate.fieldsEmpty'), '', {
          confirmButtonText: 'OK'
        });
        }

        this.countries = countries.map((c) => {
          return {
            label: "+" + c.countryCode,
            name: c.country,
            code: c.code,
            countryCode:c.countryCode,
            phoneLength: c.phoneLength,
            flag: c.flag,
            prefix: c.prefix ? c.prefix : null
          }
        })        
      }
      if(this.active == 4){
        this.clientPhone = this.clientPhone.replace(/\s+/g, '');
        const emptyPhoneFieldsCant = this.clientPhone.trim().length;
        const onlyNumbers = (/^\d+$/).test(this.clientPhone);
        if(emptyPhoneFieldsCant == 0){
          this.active--;
          this.$alert(this.$t('chatTemplate.fieldsEmpty'), '', {
          confirmButtonText: 'OK'
          });
        }else{
          if(!onlyNumbers || emptyPhoneFieldsCant > 14){
            this.active--;
            this.$alert(this.$t('chatTemplate.invalid-destination'), '', {
            confirmButtonText: 'OK'
            });
          }else{
            if(!this.selectedCountry){
              this.active--;
              this.$alert(this.$t('chatTemplate.required-country'), '', {
                confirmButtonText: 'OK'
              });
            }
          }
        } 
      }
      if(this.active == 5){
        this.isLoading = true
        this.$emit('sendingTemplate')
        const resp = await this.$store.dispatch('agentConsoleChat/createAction',{
           "organizationId": this.getUser.organization_session.id,
           "type" : "send_template",
           "templateId" : this.selectedTemplateId,
           "applicationId" : this.applicationId,
           "phone": this.phone,
           "departmentId": this.departmentSelectedId,
           "params": [
            ...this.templateVariables
           ],
           "need_response": true,
         });
        this.$store.dispatch('agentConsoleChat/listenToChatEngineAction',{
            key: resp.key,
            callback: (data) => {
              if (data && "status" in data) {
                if (data.status === 'success') {
                    this.$message({
                      message: this.$t('chatTemplate.theTemplateWasSended', { phone: this.clientPhone}),
                      type: "success"
                  })  
                } else {
                    const chat = this.$store.getters['_chats/getChatsByPhone'](this.phone)
                    console.log(chat)
                    if(!chat){
                      this.$message({
                        message: this.$t('chatTemplate.thereWasAProblemTryingToSendTheTemplate', { phone: this.clientPhone}),
                        type: "error"
                      })
                    }else{
                      this.$message({
                        message: this.$t('chatTemplate.theChatWasAlreadyCreated', { phone: this.clientPhone}),
                        type: "error"
                      })
                    }
                }
                this.isLoading = false
                this.$emit("closeModal");
                this.$store.dispatch("agentConsoleChat/removeAction", resp.key)
              }
            }
        })
      }
    },
    backStep(){
      if(this.active-- < 0) this.active = 0;
    },
    async refreshTemplates(){
      this.resetCurrentPage({department: this.selectedDepartment})
      await this.fetchTemplatesLocal()
    },
    async fetchTemplatesLocal(add=false){
      this.isLoading = true;
      await this.fetchTemplates({department: this.selectedDepartment, add})
      if(this.templates[this.departmentSelectedId] && this.templates[this.departmentSelectedId].length > 0){
        this.selectedTemplateId = this.templates[this.departmentSelectedId][0].id;
        this.selectedTemplateContent = this.templates[this.departmentSelectedId][0];
        this.categories  = this.templates[this.departmentSelectedId].map((element) => {
          return element.category
        })                    
        this.categories = [... new Set(this.categories)]
        this.categoriesFiltered = this.categories
      }else{
        this.$msgbox({
          message: this.$t('chatTemplate.notExistTemplates'),
        })
        this.active = 0;
      }
      this.isLoading = false;
    },
    selectTemplate(id){
      this.selectedTemplateId = id;
      this.selectedTemplateContent = this.templates[this.departmentSelectedId].filter((element) => element.id == this.selectedTemplateId)[0];
    },
    async restartSteps(){
      this.active = 0;
      this.clientPhone = ''
      this.querySelectedCountry = ''
      this.templateVariables = [];
      this.isLoading = false;
      this.templateFilter = ''  
    },
    async getStartData(){
      this.isLoading = true;
      const response = await callcenter2Connection.getDepartments();
      if(response.data.departments && response.data.departments.length > 0){
        this.departmentsWithNumber = response.data.departments.filter((element) => element.number !== null);
        this.departmentSelectedId = this.departmentsWithNumber[0].id 
      }
      this.isLoading = false;
      this.sendTemplateDoc = process.env.VUE_APP_SEND_TEMPLATE_DOC;
    },
    filterMethod(query) {
        this.querySelectedCountry = query
    },
    async handleScroll(){
      const container = this.$refs.scrollContainer;
      // Verifica si se llegó al final del contenedor
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        if(this.currentPage(this.departmentSelectedId) <= this.lastPage && !this.isLoading){
          this.isLoading = true;
          await this.fetchTemplatesLocal(true)
          this.isLoading = false;
        }
      }
    }
  },
  components: {
    TheChatPreview
  },
  computed: {
    ...mapGetters({getUser:'getUser', config : 'getConfiguration', 
    templates: 'whatsappTemplates/getTemplates',
    lastPage: 'whatsappTemplates/getLastPageByDepartment',
    currentPage: 'whatsappTemplates/getCurrentPageByDepartment',
    isLoadingTemplates: 'whatsappTemplates/getIsLoading',
    applicationId: 'whatsappTemplates/getApplicationId'
  }),
    selectedDepartment(){
      return this.departmentsWithNumber.filter((element) => element.id == this.departmentSelectedId)[0]
    },
    templateString(){
      let templateModified = this.selectedTemplateContent.text;
      if(this.selectedTemplateContent.text !== '' && this.templateVariables.length > 0){
        
        for(let i = 0; i < this.templateVariables.length; i++){
      
          templateModified = templateModified.replace(`{{${i+1}}}`, this.templateVariables[i] !== '' ? this.templateVariables[i] : `{{${i+1}}}`);
        }
      }
      return templateModified;
    },
    filteredTemplate(){
      if(!this.templates[this.departmentSelectedId]) return []
      return this.templates[this.departmentSelectedId].filter((element) => {
        if(this.categoriesFiltered.length === 0){
          return false
        }
        if(this.categoriesFiltered.includes(element.category)){
          
          return this.templateFilter.length > 3 ? element.name.includes(this.templateFilter) : true
           
        }
      })
    },
    selectedCountryObj(){
      return this.countries.filter((element) => element.countryCode === this.selectedCountry)[0]
    },
    filteredCountries(){
      return this.querySelectedCountry.length > 0 ? this.countries.filter((element) => element.name.toLowerCase().includes(this.querySelectedCountry)): this.countries
    
    },
    phone() {
      const prefix = this.selectedCountryObj.prefix ?? ''
      return `${this.selectedCountryObj.countryCode}${prefix}${this.clientPhone}`
    }
  },
  async created(){
    this.getStartData();
    this.handleKeydown = (event) => {
      if (event.code === 'Enter' && !this.isLoading) {
        this.nextStep();
        event.preventDefault();
        event.stopPropagation();
      }
    };
    window.addEventListener('keydown', this.handleKeydown);
  },
  destroyed(){
    window.removeEventListener('keydown' , this.handleKeydown)
  }
}
</script>
<style>
.step-view .card .el-card__header{
  padding: 10px 15px !important;
  border-color: #D4D4D4 !important;
  cursor: pointer;
  text-align: center;
}
.step-view .card {
  overflow: visible;
  border-color: #D4D4D4 !important;
}
.step .el-step__title{
  font-size: 13px;
}
.step .el-step__main .el-step__title.is-process{
  color: #409EFF;
}
.step .el-step__head.is-process{
  color: #409EFF;
  border-color: #409EFF;
}
.step-view-1 .card.selected{
  color: #409EFF;
}
.step-view-1 .card.selected {
  border-color: #409EFF !important;
}
.step-view-1.is-loading{
  overflow: hidden !important;
}
.selectedIcon .el-radio__label{
  display: none;
}
.formItem-container-step-view-3 .el-input input:nth-child(1){
  width: 100% !important;
}
.step-view-3 .el-form-item__content{
  width: 80% !important;
}
.formItem-step-view-3 .formItem-container-step-view-3-select-country .el-input__inner{
  padding-left: 40px !important
}
.formItem-container-step-view-3-option-country{
  display: flex;
  gap: 5px;
  align-items: center;
}
.step-view-1__form__filter-item .el-form-item__content{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.step-view-1__form__filter-item-container{
  display: flex;
}
.step-view-1__form__filter-item-refresh-templates-button i{
  margin-right: 5px;
}
.step-view-1__form__filter-item-dropdown-menu{
  padding: 10px !important;
}
</style>
<style scoped>
.step-view{
  max-height: 50vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stepsView{
  margin-top: 20px;
  width: 100%;
  height: 100%;
}
.contentContainer{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.contentContainer .contentContainer_btns{
  display: flex;
  justify-content: space-between;
}
.contentContainer .contentContainer_onlyOneBtn{
  display: flex;
  justify-content: flex-end;
}
.selectedIcon{
  position: absolute;
  top: 10px;
  right: 20px;
}
.step-view-1{
  display: flex;
  flex-direction: row;
  height: 500px;
  max-height: 80vh;
  padding: 5px 0;
}
.step-view-1__templates-filter-container .el-dropdown-link{
  cursor: pointer;
}
.step-view-1__templates-filter-container{
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 70%;
  padding: 5px;
  padding-top: 0;
}
.step-view-1__templates-container{
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.step-view-1 .card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
  cursor: pointer
}
.step-view-1__form__filter-item-input{
  width: 100%;
}
.step-view-1__form__filter-item{
  width: 100%;
}
.template-card-subtitle{
  font-size: 12px;

}
.step-view-2{
  display: flex;
  flex-direction: row;
  height: 500px;
  padding: 5px 0;
  max-height: 80vh;
}
.step-view-2 .form{
  width: 70%;
  max-height: 70vh;
  overflow: scroll;
  padding: 5px;

}
.step-view-2 .form .el-form-item{
  width: 100%;
}
.step-view-1__form__filter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-view-1__form__filter-item{
  margin: 0%;
  padding: 0 0 20px 0;
  position: sticky;
  top: 0
}
.formItem-step-view-3{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.formItem-container-step-view-3{
  display: flex;
  gap: 10px;
  width: 100%;
}
.formItem-step-view-3-help{
  font-size: 12px;
  color: rgba(255,0,0,0.8);
}
.formItem-container-step-view-3 .formItem-container-step-view-3-select-country{
  width: 180px;
}
.formItem-step-view-3-help-container{
  display: flex;
  flex-direction: column;
}
.step-view-4{
  max-height: 80vh;
  display: flex;
  flex-direction: row;
  height: 500px;
  padding: 5px 0;
}
.step-view-4 .form{
  width: 70%;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  padding: 5px;

}
.step-view-4 .form .el-form-item{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.step-view-4__chat-preview-container{
  width: 40%;
  height: 50%;
}
.step-view-5 img {
  display: block;
  margin: 0 auto;
}
.step-view-0{
  overflow: hidden;
}
</style>