import PhoneWidgetsList from '../../../views/phone-widgets/List.vue'
import ThePhoneWidgetsForm from '../../../views/phone-widgets/Form.vue'
const routes = [
  {
    name: "phoneWidgetsList",
    path: "/contact-me/phone-widgets",
    component: PhoneWidgetsList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "phoneWidgetsNew",
    path: "/contact-me/phone-widgets/new",
    component: ThePhoneWidgetsForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "phoneWidgetsEdit",
    path: "/contact-me/phone-widgets/:id/edit",
    component: ThePhoneWidgetsForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  }
]

export default routes;