import http from "./http";

export default {
  saveWidgetParams(organizationId, widgetParams){
    return http.post(`/phone_widgets/params/${organizationId}`, {
      ...widgetParams
    });
  },
  getWidgetParams(organizationId, callback){
    return http.get(`/phone_widgets/params/${organizationId}/${callback}`);
  },
  getConnectionData(callback){
    return http.get(`/phone_widgets/callback/${callback}`);
  },

  uploadLogoImage(organizationId, callback, formData){
    return http.post(`/phone_widgets/params/upload-logo/${organizationId}/${callback}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'  // Asegúrate de enviar el encabezado correcto
      }
    }) 
  },
  togglePhoneWidgetActive(id, active) {
		const data = { isActive : active }
		return http.put(`/phone_widgets/${id}/active`, data);
	},
}