<template>
  <el-dialog :visible.sync="isOpen" :title="$t('contact-me.chat-center.quick-response-template.menu-title')" top="10vh" modal-append-to-body>
    <div class="quick-response-template__container" v-loading="isLoading">
      <div class="quick-response-template__search-container">
        <TheSearchInput :placeholder="$t('contact-me.chat-center.quick-response-template.place-holder')" v-model="nameFiltered"></TheSearchInput>
        <el-button @click="filterTemplates" type="primary">Buscar</el-button>
      </div>
      <div class="quick-response-template__card-container" @scroll="handleScroll">
        <el-card :class="['quick-response-template__card', {'selected': selectedTemplate.id === template.id}]" @click.native="selectTemplate(template.id)" :header="template.name" v-for="(template, key) in templates" :key="key">
          <span>{{ template.description }}</span>
        </el-card>
      </div>
    </div>
    <div class="quick-response-template__footer">
      <el-button v-if=" templates && templates.length > 0" @click="saveSelectedTemplate" type="primary">{{$t('common.accept')}}</el-button>
    </div>    
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import TheSearchInput from '../html/TheSearchInput.vue'

export default {
  props:{
    isExternalOpen: {
      required: true
    }
  },
  components: {
    TheSearchInput
  },
  data(){
    return {
      isOpen: false,
      templates: [],
      isLoading: false,
      selectedTemplate: {},
      nameFiltered: '',
      page: 1,
      pagination: {}
    }
  },
  computed: {
    ...mapGetters({
      agent: "getAgent",
      getPagination: "quickResponseTemplates/getPagination",
      getData: "quickResponseTemplates/getData" 
    })
  },
  methods: {
    async filterTemplates(){
      if(this.nameFiltered.trim().length === 0){
        this.isLoading = true;
        await this.fetchQuickResponseTemplates()
        this.isLoading = false;
      }else{
        this.isLoading = true;
        await this.fetchQuickResponseTemplates({name: this.nameFiltered})
        this.isLoading = false;
      }
    },
    async fetchQuickResponseTemplates(params){
      const organizationId = this.agent.organization_id
      const {departments} = this.agent
      console.log(departments)
      const departmentsId = departments.map((element) => {
        return element.id
      })
      await this.$store.dispatch('quickResponseTemplates/all', { 'organization': organizationId, page: 1, ...params})
      this.templates = this.getData
      this.templates.filter((element) =>{
        return !element.department || element.department.id in departmentsId 
      })

      this.selectedTemplate = this.templates[0]
    },
    saveSelectedTemplate(){
      this.$emit('selectQuickResponseTemplate', this.selectedTemplate.description)
      this.isOpen = false;
    },
    selectTemplate(templateId){
      
      this.selectedTemplate = this.templates.filter((element) => {
        return element.id === templateId
      })[0]
    },
    async handleScroll(event){
      const container = event.target;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        if (!this.isLoading && this.pagination.page < this.pagination.totalPages) {
          this.isLoading = true;
          await this.$store.dispatch('quickResponseTemplates/loadNextPage')
          this.pagination = this.getPagination
          this.templates = this.getData
          this.isLoading = false;

          // if(this.nameFiltered.trim().length > 0){
          //   this.templates = await this.fetchQuickResponseTemplates({ name: this.nameFiltered, page: this.page }).then(() => {
          //     this.isLoading = false;
          //   });  
          // }else{
          //   this.templates = await this.fetchQuickResponseTemplates({ page: this.page }).then(() => {
          //     this.isLoading = false;
          //   });
          // }
          
        }
      }
    },
    
  },
  watch:{
    isExternalOpen(){
      this.isOpen = this.isExternalOpen
    },
    isOpen(newValue){
      if(!newValue) this.$emit('closeDialog')
    },
  },
  
  async created(){
    this.isLoading = true;
    await this.fetchQuickResponseTemplates({})
    this.templates = this.getData
    this.pagination = this.getPagination
    this.isLoading = false;
  }
}
</script>
<style>
.quick-response-template__card.el-card{
  overflow: visible;
  height: fit-content;
  cursor: pointer;
}
</style>
<style scoped>
.quick-response-template__footer{
  display: flex;
  width: 100%;
  justify-content: flex-end
}
.quick-response-template__card.selected {
  border-color: #409EFF ;
}
.quick-response-template__container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 400px;
}
.quick-response-template__search-container{
  display: flex;
  top: 0;
  gap: 5px;
}
.quick-response-template__card-container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 0;
  overflow: auto;
}
</style>