import Vue from "vue"
import Vuex from "vuex"

import createStore from "./helper";
import createPBXStore from "./pbx";

import auth from "./modules/auth";
import user from "./modules/user";
import organizations from "./modules/organizations";
import chats from "./modules/chats";
import _users from "./modules/_users";
import group from './modules/groups';
import janus from "./modules/janus-corpo";
import firebase from "./modules/firebase";
import groupModal from './modules/GroupModal';
import callHistory from "./modules/call-history";
import configuration from "./modules/configuration";
import videocalls from "./modules/videocall";
import agentConsole from "./modules/agent-console";
import _chats from "./modules/_chat";
import notifications from './modules/user-notifications';
import agentConsoleChat from "./modules/agent-console-chat";
import tickets from "./modules/tickets";
import sideImages from './modules/side-images';
import theme from './modules/theme';
import agentFirebase from './modules/agent-firebase';
import agentsFirebase from './modules/agents-firebase';
import queuesFirebase from './modules/queues-firebase';
import tours from "./modules/tours";
import contacts from "./modules/contacts"
import organization_contacts from './modules/organization-contact'
import corpochat from "./modules/corpo-chat"
import corpocalls from "./modules/corpo-call"
import mailcenter from './modules/mail-center'
import audit from './modules/audit'
import call_disposition from './modules/call-disposition'
import reports from './modules/reports'
import form from './modules/form';
import extensions from './modules/extensions'
import users from './modules/users'
import bigQueryReports from './modules/big-query-reports'
import contactMe from "./modules/contact-me"
import pbxExtensions from "./modules/pbxExtensions"
import departments from "./modules/chat-center"
import gupshup from "./modules/gupshup";
//import pbxAudios from "./modules/pbxAudios"
import clients from './modules/clients'
import integrations from "./modules/integrations"
import zoho from "./modules/zoho";
import customers from "./modules/customers"
import extensionsFirebase from "./modules/extensions-firebase"
import callsExtensionsFirebase from "./modules/calls-extensions-firebase"
import hipermeForms from "./modules/hiperme-form"
import phoneWidgets from "./modules/phone-widgets";
import agentChatDashboard from './modules/agent-chat-dashboard';
import whatsappTemplates from "./modules/whatsappTemplates";
Vue.use(Vuex)

const store = new Vuex.Store({
	modules : {
		auth,
		user,
		firebase,
		form,
		users,
		extensions,
		organizations,
		agents : createStore('agents'),
		mediaservers : createStore('media_servers'),
		firebases : createStore('firebases'),
		ostickets : createStore('ostickets'),
		livehelperchats : createStore('live_helper_chats'),
    clients,
		licenses: createStore('licenses'),
    servers : createStore('servers'),
    chatProviders : createStore('chat-providers/'),
    bots : createStore('bots/'),
    cacheChats : createStore('cache-chats'),
	timeConditions: createStore('time_conditions'),
	timeConditionsHour: createStore('time_condition_hours'), 
		quickButtons: createStore('quick_buttons'), 
		forms: hipermeForms,
		formsInputs: createStore('form_inputs'), 
		chatClassifications: createStore('chat_classifications'), 
		contactMe,
		chats,
		janus,
		group,
		groupModal,
		callHistory,
		configuration,
		videocalls,
		agentConsole,
		_chats,
		notifications,
		tickets,
		agentConsoleChat,
		sideImages,
		theme,
		agentFirebase,
    agentsFirebase,
    queuesFirebase, 
		_users,
		tours,
		contacts,
		organization_contacts,
		corpochat,
		corpocalls,
		mailcenter,
		audit,
		call_disposition,
		reports,
		bigQueryReports,
		departments,
		gupshup,
		integrations,
		zoho,
		pbx_extensions: pbxExtensions,
		pbx_queues: createPBXStore("queues", "queue"),
		pbx_destinations: createPBXStore("destinations/all"),
		pbx_ivrs: createPBXStore("ivr", "ivr"),
		pbx_system_recordings: createPBXStore("system-recordings", "recording"),
		pbx_music_categories: createPBXStore("music-categories", "music"),
		pbx_agents: createPBXStore("agents", "agent"),
		chatDispositions: createStore('chat_dispositions'),
		customers,
		extensionsFirebase,
		callsExtensionsFirebase,
		quickResponseTemplates: createStore('quick_response_templates'),
		phoneWidgets,
		agentChatDashboard,
		whatsappTemplates
	},
})

export default store