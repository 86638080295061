import TheContactMePanel from '@/views/contact-me-configuration/TheContactMePanel.vue';
import ThePBXRoutes from "./contact-me/pbx";
import TheChatCenterRoutes from "./contact-me/chat-center";
import store from '../../store'
import extensions from "./pbx-configurations/extensions";
import queues from "./pbx-configurations/queues";
import ivrs from "./pbx-configurations/ivrs";
import audios from "./pbx-configurations/audios";
import ThePhoneWidgetRoutes from './contact-me/phone-widgets'

const routes = [
  {
    name: "contact-me-config",
    path: "/contact-me",
    component: TheContactMePanel,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  ...extensions,
  ...queues,
  ...ivrs,
  ...audios,
  ...ThePBXRoutes,
  ...TheChatCenterRoutes,
  ...ThePhoneWidgetRoutes
].map(route => {
  route.beforeEnter  = (to, from, next) => {
    if (store.getters["contactMe/getOrganizationId"]) {
      next();
    } else {
      next({ name: "organization"});
    }
  }
  return route;
})

export default routes;