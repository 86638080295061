<template>
  <div
    class="phone-button"
    :style="{
      backgroundColor: getBackgroundColor,
      width: getButtonSize,
      height: getButtonSize,
    }"
    @click="open"
  >
    <img
      class="phone-button__widget-icon"
      crossorigin="anonymous"
      v-if="phoneWidgetButtonsParams.data.logoImage"
      :style="{
        height: phoneWidgetButtonsParams.styles.logoDimension,
        width: phoneWidgetButtonsParams.styles.logoDimension,
      }"
      :src="phoneWidgetButtonsParams.data.logoImage"
      alt="widget-button-icon"
    />
    <canvas ref="canvas" style="display: none"></canvas>
    <p :style="{ 'font-size': phoneWidgetButtonsParams.styles.iconFontSize }">
      {{ phoneWidgetButtonsParams.data.iconMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    phoneWidgetButtonsParams: {
      required: true,
      type: Object,
    },
    isInCall: {
      required: true,
      type: Boolean,
    },
    isCalling: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    open() {
      this.$emit("click");
    },
  },
  computed: {
    getBackgroundColor() {
      // Retorna el color de fondo en función del estado
      if (this.isInCall) {
        return this.phoneWidgetButtonsParams.styles.widgetBtnCallBackground;
      }
      if (this.isCalling) {
        return this.phoneWidgetButtonsParams.styles.widgetBtnCallingBackground;
      }
      return this.phoneWidgetButtonsParams.styles.widgetBtnIdleBackground;
    },
    getButtonSize() {
      return this.phoneWidgetButtonsParams.styles.widgetBtnSize;
    },
  },
};
</script>

<style scoped>
.phone-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 200;
  background: var(--widget-btn-idle-background);
  width: var(--widget-btn-size);
  height: var(--widget-btn-size);
}
.phone-button__widget-icon {
  width: var(--widget-btn-image-size);
  height: var(--widget-btn-image-size);
}

.phone-button.phone-button--call {
  background: var(--widget-btn-call-background);
}
.phone-button.phone-button--calling {
  background: var(--widget-btn-calling-background);
}
</style>
