<template>
  <the-form ref="form" :form="form" :store="store" class="crud form" :id="id" :edit="edit" :externalLoading="isLoading" :entity="entity" :title="title" :submit="beforeSubmit" :afterSubmit="afterSubmit">
    <template v-slot:inputs>
      <!-- <el-form-item style="display: none;" class="callback-config" :label="$t('phone-widget.callback')">
        <el-input :disabled="true" v-model="widgetParams.callback"></el-input>
      </el-form-item> -->
      <el-form-item class="origins-config" :label="$t('phone-widget.origins')">
        <div class="origins-container">
          <el-card class="origin-card" v-for="origin in origins" :key="origin.id">{{ origin.name }} <i @click="deleteOrigin(origin.id)" class="fas fa-trash-alt delete-icon"></i></el-card>
          <p v-if="origins.length === 0">{{ $t('phone-widget.access-all-origins') }}</p>
        </div>
        <span class="origins-config__helper-text">{{ $t('phone-widget.origins-helper-text') }}</span>
        <div class="origins-config__inputs-container">
          <el-input v-model="newOrigin" type="text" :placeholder="$t('phone-widget.placeholder-enter-origin')"></el-input>
          <el-button class="origins-config__inputs-add" type="primary" @click="addOrigin">{{ $t('phone-widget.add') }}</el-button>
        </div>
      </el-form-item>
      <div class="widget-config__customization">
        <h3>{{ $t('phone-widget.widget-customization-title') }}</h3>
        <div class="widget-config__customization-container">
          <div class="widget-config__params-wraper">
            <div v-if="currentStep==0" class="widget-config__params-container">
              <div class="widget-config__params-group">
                <h4>{{$t('phone-widget.widget-customization.logo-configuration-title')}}</h4>
                <div class="widget-config__params-grid">
                  
                  <el-form-item :label="$t('phone-widget.widget-customization.label.upload-logo')">
                    <el-upload
                      :action="''"
                      :show-file-list="true"
                      :on-change="handleImageChange"
                      :file-list="imageFileList"
                      :before-upload="checkImageBeforeUpload"
                      >
                      <el-button type="primary">{{ $t('phone-widget.widget-customization.placeholder.logo') }}</el-button>
                    </el-upload>
                  </el-form-item>
                  <!-- <el-form-item label="Logo">
                    <el-input v-model="widgetParams.data.logoImage" :placeholder="$t('phone-widget.placeholder-logo')"></el-input>
                  </el-form-item> -->
                  <el-form-item :label="$t('phone-widget.widget-customization.label.logo-size')">
                    <el-input type="text" :placeholder="$t('phone-widget.widget-customization.placeholder.logo-size')" v-model="widgetParams.styles.logoDimension"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="widget-config__params-group">
                <h4>{{$t('phone-widget.widget-customization.icon-configuration-title')}}</h4>
                <div class="widget-config__params-grid">
                  <el-form-item :label="$t('phone-widget.widget-customization.label.icon-size')">
                    <el-input type="text" :placeholder="$t('phone-widget.widget-customization.placeholder.icon-size')" v-model="widgetParams.styles.widgetBtnSize"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.icon-text')">
                    <el-input type="textarea" resize="none" :placeholder="$t('phone-widget.widget-customization.placeholder.icon-text')" v-model="widgetParams.data.iconMessage"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.icon-text-size')">
                    <el-input type="text" :placeholder="$t('phone-widget.widget-customization.placeholder.icon-text-size')" v-model="widgetParams.styles.iconFontSize"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="widget-config__params-group">
                <h4>{{$t('phone-widget.widget-customization.widget-configuration-title')}}</h4>
                <div class="widget-config__params-grid">
                  <el-form-item class="color-picker-container" :label="$t('phone-widget.widget-customization.label.widget-background')">
                    <!-- <el-input type="textarea" resize="none" rows="6" placeholder="Ingrese el color de fondo del widget" v-model="widgetParams.styles.widgetBackground"></el-input> -->
                    <el-color-picker v-model="widgetParams.styles.widgetBackground"></el-color-picker>
                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.widget-fonts-family')">
                    <el-input type="text" :placeholder="$t('phone-widget.widget-customization.placeholder.widget-fonts-family')" v-model="widgetParams.styles.fontFamily"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.widget-orientation')">
                    <el-select @change=handleOrientation :placeholder="$t('phone-widget.widget-customization.placeholder.widget-orientation')" v-model="widgetOrientation">
                      <el-option value="left" :label="$t('phone-widget.widget-customization.label.widget-orientation-left')"></el-option>
                      <el-option value="right" :label="$t('phone-widget.widget-customization.label.widget-orientation-right')"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>    
            <div v-if="currentStep==1" class="widget-config__params-container">
              <div class="widget-config__params-group">
                <h4>{{ $t('phone-widget.widget-customization.widget-idle-configuration-title') }}</h4>
                <div class="widget-config__params-grid">
                  <el-form-item class="color-picker-container" :label="$t('phone-widget.widget-customization.label.widget-minimized-idle-background-color')">
                    <!-- <el-input type="text" placeholder="Ingrese el color de fondo de icono" v-model="widgetParams.styles.widgetBtnIdleBackground"></el-input> -->
                    <el-color-picker v-model="widgetParams.styles.widgetBtnIdleBackground" size="medium"></el-color-picker>

                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.widget-idle-main-text')">
                    <el-input type="textarea" resize="none" :placeholder="$t('phone-widget.widget-customization.placeholder.widget-idle-main-text')" v-model="widgetParams.data.idleState.mainMessage"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div v-if="currentStep==2" class="widget-config__params-container">
              <div class="widget-config__params-group">
                <h4>{{ $t('phone-widget.widget-customization.widget-calling-configuration-title') }}</h4>
                <div class="widget-config__params-grid">
                  <el-form-item class="color-picker-container" :label="$t('phone-widget.widget-customization.label.widget-minimized-calling-background-color')">
                    <!-- <el-input type="text" placeholder="Ingrese el color de fondo de icono" v-model="widgetParams.styles.widgetBtnCallingBackground"></el-input> -->
                    <el-color-picker v-model="widgetParams.styles.widgetBtnCallingBackground" size="medium"></el-color-picker>
                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.widget-calling-main-text')">
                    <el-input type="textarea" resize="none" :placeholder="$t('phone-widget.widget-customization.placeholder.widget-calling-main-text')" v-model="widgetParams.data.secondaryMessage"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div v-if="currentStep==3" class="widget-config__params-container">
              <div class="widget-config__params-group">
                <h4>{{ $t('phone-widget.widget-customization.widget-in-call-configuration-title') }}</h4>
                <div class="widget-config__params-grid">
                  <el-form-item class="color-picker-container" :label="$t('phone-widget.widget-customization.label.widget-minimized-in-call-background-color')">
                    <!-- <el-input type="text" placeholder="Ingrese el color de fondo de icono" v-model="widgetParams.styles.widgetBtnCallBackground"></el-input> -->
                    <el-color-picker v-model="widgetParams.styles.widgetBtnCallBackground" size="medium"></el-color-picker>

                  </el-form-item>
                  <el-form-item :label="$t('phone-widget.widget-customization.label.widget-in-call-main-text')">
                    <el-input type="textarea" resize="none" :placeholder="$t('phone-widget.widget-customization.placeholder.widget-in-call-main-text')" v-model="widgetParams.data.mainMessage"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div :class="['widget-config__button-container', {'widget-config__button-container--only-one-right': currentStep === 0, 'widget-config__button-container--only-one-left': currentStep === 3}]">
              <el-button v-if="currentStep > 0" type="info" @click="backStep">{{$t('common.back')}}</el-button>
              <el-button v-if="currentStep < 3" type="primary" @click="nextStep">{{$t('common.next')}}</el-button>
            </div>
          </div>
          <div class="widget-config__preview">
            <div class="widget-config__generate-container" :style="!isPhoneWidgetOpen? `background: rgba(0,0,0, 0.2);align-items:${widgetOrientation === 'left' ?  'flex-start' : 'flex-end'};` : ''">
              <PhoneWidget ref="phoneWidget" :phoneWidgetParams="widgetParams" @closeWidget="isPhoneWidgetOpen=false" @openWidget="isPhoneWidgetOpen = true"></PhoneWidget>
              <!-- <el-button class="widget-config__generate-button" @click="generatePhoneWidget" type="primary">{{ $t('phone-widget.generate-widget') }}</el-button> -->
            </div>
            <div class="widget-config__states-test-container">
              <el-button :class="['widget-config__states-test-btn', {'selected': currentPhoneWidgetState === 'idle'}]" type="info" @click="handleWidgetState('idle')">{{ $t('phone-widget.status.idle') }}</el-button>
              <el-button :class="['widget-config__states-test-btn', {'selected': currentPhoneWidgetState === 'calling'}]" type="primary" @click="handleWidgetState('calling')">{{ $t('phone-widget.status.calling') }}</el-button>
              <el-button :class="['widget-config__states-test-btn', {'selected': currentPhoneWidgetState === 'inCall'}]" type="success" @click="handleWidgetState('inCall')">{{ $t('phone-widget.status.inCall') }}</el-button>
            </div>
          </div>
        </div>
      </div>
      
    </template>
  </the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
import PhoneWidget from './PhoneWidget.vue'
import http from '@/api/phoneWidget.js'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      store: "phoneWidgets",
      widgetParams: {
        styles: {
          widgetBtnIdleBackground: "#A7C71D",
          widgetBtnCallBackground: "#A7C71D",
          widgetBtnCallingBackground: "#A7C71D",
          widgetBtnSize: "70px",
          iconFontSize: '12px',
          widgetBackground: "rgba(204,223,126,1)",
          btnCallBackground: "rgba(243, 33, 33)",
          btnCallingBackground: "rgba(212, 225, 87, 1)",
          btnIdleBackground: "rgba(167, 199, 29, 1)",

          actionBtnSize: "50px",

          fontFamily: "sans-serif, 'Gill Sans'",

          logoDimension: '20px',
          orientationLeft: 'auto',
          orientationRight: '20px'
        },
        data:{
          idleState: {
            mainMessage: 'Estamos a un llamado de distancia'
          },
          callingState: {
            mainMessage: 'Llamando',
            secondaryMessage: 'Coloquese los auriculares para una mejor experiencia'
          },
          inCallState: {
            mainMessage: 'En llamada'
          },
          logoImage: 'https://firebasestorage.googleapis.com/v0/b/simplificloud-1534336262931.appspot.com/o/hiperpbx_logo.png?alt=media&token=83683a55-8f3b-4510-bfaf-7e85db7b9717',
          iconMessage: 'Hiperpbx'
        },
        callback: '',
      },
      form: {
        nodes: [
          { component : 'input', index : 'name', label : this.$t('common.name'), required : true },
          { component : "select", index : "extension", label : this.$t("contact-me.pbx.extensions.extension"), store : 'extensions', required : true, 'label-value' : ['name'], 'index-value' : "@id", params: { ["organization.id"] : this.$store.getters["contactMe/getOrganizationId"], ["used"]: false}},
          // { component : "select", index : "type", label : this.$t("contact-me.chat-center.quick-response-template.type") , required: true, values: [
          //   { label: 'text', value: "TEXT" }
          // ]},
          { component : 'input', index : 'callTo', label : this.$t('phone-widget.destination'), type: 'number', required : true },

        ]
      },
      currentPhoneWidgetState: 'idle',
      currentStep: 0,
      htmlContent: '',
      uploadedStylesFile: {
        name: '',
        content: '',
        file: null
      },
      isLoading: false,
      isPhoneWidgetOpen: false,
      origins: [],
      newOrigin: '',
      imageFileList: [],
      uploadedLogoImageUrl: '',
      widgetOrientation: 'right'
    }
  },
  components: {
    TheForm,
    PhoneWidget
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("phone-widget.title-edit") : this.$t("phone-widget.title-new")
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    },
    getConfig(){
      return this.$store.getters['getConfiguration']
    }
  },
  async created(){
    this.isLoading = true;
    this.widgetParams.callback = this.entity ? this.entity.callback : crypto.randomUUID();
    if(this.entity){
      const respose = await http.getWidgetParams(this.organizationId, this.widgetParams.callback) ?? {}
      const aux = respose.data
      aux.data.logoImage = !await this.isValidImage(`https://storage.googleapis.com/${this.getConfig.storageBucket}/phone-widgets/${this.organizationId}/${this.widgetParams.callback}.png`) ? 'https://firebasestorage.googleapis.com/v0/b/simplificloud-1534336262931.appspot.com/o/hiperpbx_logo.png?alt=media&token=83683a55-8f3b-4510-bfaf-7e85db7b9717' : `https://storage.googleapis.com/${this.getConfig.storageBucket}/phone-widgets/${this.organizationId}/${this.widgetParams.callback}.png?nocache=${Date().toString()}`
      this.widgetParams = {...this.widgetParams, ...aux}
      if(this.widgetParams.styles.orientationLeft === '20px'){
        this.widgetOrientation = 'left'
      }else{
        this.widgetOrientation = 'right'
      }
      this.origins = this.entity.allowedOrigins ?? [];
      this.origins = this.origins.map((element) => {
        return {
          id: this.generateOriginKey(element),
          name: element
        }
      })
    }
    this.isLoading = false;
  },
  methods: {
    async isValidImage(url) {
      const response = await fetch(url)
      return response.status === 200;
    },
    addOrigin(){
      this.origins.push({id: this.generateOriginKey(this.newOrigin), name: this.newOrigin});
      this.newOrigin = '';
    },
    deleteOrigin(id){
      this.origins = this.origins.filter((element) => element.id !== id)
    },
    generateOriginKey(name){
      const date = new Date();
      return name + date.toISOString().slice(0,10);
    },
    beforeSubmit(data) {
      data.callback = this.widgetParams.callback;
      data.allowedOrigins = this.origins.map((element) => element.name);
      data.callTo = data.callTo.toString();
      return data;
    },
    async updateExtension(ext, used) {
      try {
        this.isLoading = true
        const id = ext.replace('/api/extensions/', '')
        const extension = await this.$store.dispatch('extensions/one', id)
        extension.used = used ? 'true' : 'false'
        extension.organization = extension.organization['@id'];
        await this.$store.dispatch('extensions/update', { id, payload : extension })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async afterSubmit(data){
      if (this.imageFileList.length > 0) {
        const formData = new FormData();
        formData.append('file', this.imageFileList[0]?.raw);
        formData.append('organizationId', this.organizationId);

        try {
          const response = await http.uploadLogoImage(this.organizationId, this.widgetParams.callback, formData);
          this.uploadedLogoImageUrl = response.data.firebasePath;
          this.widgetParams.data.logoImage = `https://storage.googleapis.com/${this.getConfig.storageBucket}/${this.uploadedLogoImageUrl}`;
        } catch (error) {
          console.error('Error al subir la imagen:', error);
        }
      }
      if(this.widgetOrientation === 'left'){
        this.widgetParams.styles.orientationLeft = '20px'
        this.widgetParams.styles.orientationRight = 'auto'
      }else{
        this.widgetParams.styles.orientationRight = '20px'
        this.widgetParams.styles.orientationLeft = 'auto'
      }
      await http.saveWidgetParams(this.organizationId, this.widgetParams);
      if (data.form.extension) {
        await this.updateExtension(data.form.extension, true)
      }
    },
    handleImageChange(file) {
      this.imageFileList[0] = file;
      this.uploadedLogoImageUrl = URL.createObjectURL(this.imageFileList[0].raw);
      this.widgetParams.data.logoImage = this.uploadedLogoImageUrl
    },
    handleWidgetState(state){
      this.currentPhoneWidgetState = state
      switch(state){
        case 'idle':
          this.$refs.phoneWidget.hangup()
          break;
        case 'calling':
          this.$refs.phoneWidget.hangup()
          this.$refs.phoneWidget.handleCall()
          break;
        case 'inCall': 
          this.$refs.phoneWidget.onCallStart()
      } 
    },
    async generatePhoneWidget(){
      let response = await fetch(`${process.env.VUE_APP_WIDGET_CDN}/phoneWidgetTemplate.html`)
      let htmlContent = await response.text()

      const stylesTag = `<link id="dynamicStylesheet" rel="stylesheet" type="text/css" href="https://storage.googleapis.com/${this.getConfig.storageBucket}/phone-widgets/${this.organizationId}/${this.widgetParams.callback}.css" crossorigin="anonymous"/>`;
      
      htmlContent = htmlContent.replace('__STYLES__', stylesTag)
      htmlContent = htmlContent.replace('__CALLBACK__', this.widgetParams.callback)

      const otroTag = '<script src="' + process.env.VUE_APP_WIDGET_CDN + '/main.js?no-cache=' + new Date().toISOString() + '"></' + 'script>';
      console.log(otroTag)
      htmlContent = htmlContent.replace('__SCRIPT__', otroTag)

      const downloadLink = document.createElement('a')
      const blob = new Blob([htmlContent], {type: 'text/plain'})
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'index.html'
      downloadLink.click()
      URL.revokeObjectURL(downloadLink.href)
    },
    backStep(){
      if(this.currentStep > 0){
        this.currentStep--;
      }
    },
    nextStep(){
      if(this.currentStep < 3){
        this.currentStep++;
      }
    },
    checkImageBeforeUpload(image){

      const sizeLimit = 3 * (1024**2);
      if(!image.type.includes('image')){
        this.$msgbox(this.$t('phone-widget.please-upload-valid-file'))
        return true;
      }
      if(image.size > sizeLimit){
        this.$msgbox(this.$t('phone-widget.please-upload-valid-size-file'))
        return true;
      }

    },
    handleOrientation(value){
      if(value === 'left'){
        this.widgetParams.styles.orientationLeft = '20px'
        this.widgetParams.styles.orientationRight = 'auto'
      }
      this.widgetParams.styles.orientationRight = '20px'
      this.widgetParams.styles.orientationLeft = 'auto'
    }
  }
}
</script>
<style>
.origin-card .el-card__body{
  padding: 10px !important;
  display: flex;
  justify-content: space-between;
}
.origins-config .el-form-item__label{
  float: none !important;
}
.widget-config__styles-form-item .el-form-item__label{
  float: none !important;
}
.widget-config__logo-form-item .el-form-item__label{
  float: none !important;
}
.color-picker-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
<style scoped>
.origins-container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  background: #eee;
  margin-bottom: var(--column);
}
.origins-container .delete-icon{
  cursor: pointer;
}
.origins-container .delete-icon:hover{
  color: rgba(255, 0 , 0, 0.6);
}

.origins-config__inputs-add{
  margin-top: 5px;
}
.origins-config__inputs-container{
  margin-bottom: var(--column);
}
.origins-config__helper-text{
  font-size: 12px;
  color: rgba(0,0,0,0.5);
}
.widget-config__preview{
  position: relative;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--column);
  margin-bottom: 15px;
}
.widget-config__customization{
  display: flex;
  flex-direction: column;
  gap: var(--column);
}
.widget-config__customization-container{
  display: flex;
  gap: var(--column);
}
.widget-config__params-wraper{
  width: 70%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.widget-config__params-container{
  display: flex;
  flex-direction: column;
  gap: var(--column);
}
.widget-config__params-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(40px, 70px);
  column-gap: var(--column);
}

.widget-config__button-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.widget-config__button-container--only-one-left{
  justify-content: flex-start;
}
.widget-config__button-container--only-one-right{
  justify-content: flex-end;
}
.widget-config__styles-container{
  display: flex;
  align-items: center;
  gap: var(--column);
}

.widget-config__upload-container{
  display: flex;
  padding: 5px;
  gap: 10px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  width: fit-content;
  height: 50px;
  overflow: hidden;
}
.widget-config__file-uploaded{
  display: flex;
  align-items: center;
  gap: 5px;
}
.widget-config__file-uploaded .fa-file-code{
  font-size: 23px;
}
.widget-config__styles-container-buttons{
  height: 38px;
}

.widget-config__states-test-container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.widget-config__states-test-container .widget-config__states-test-btn{
  width: 100px;
  margin: 0;
  display: flex;
  justify-content: center;
}
.widget-config__states-test-container .widget-config__states-test-btn.selected{
  border: 3px solid rgb(194, 224, 73);
}
.widget-config__generate-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 280px;
  gap: var(--column);
  height: 100%;
}
.widget-config__generate-button{
  width: 100%;
}
</style>